<template>
  <div id="chartDom" ref="chart"></div>
</template>

<script>
import {getCurrentInstance, onMounted} from "vue-demi";
import {reactive, toRefs} from "vue";
import * as echarts from 'echarts'
import liquidFill from "echarts-liquidfill"

export default {
  name: "OrderLiquidFillChart",
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      data: [0.45]
    })

    onMounted(() => {
      orderLiquid()
    })

    const orderLiquid = () => {
      const chartDom = proxy.$refs.chart
      if (chartDom) {
        let myChart = echarts.init(chartDom)
        myChart.option = {
          series: [
            {
              type: 'liquidFill',
              radius: '60%',
              // center: ['25%', '45%'],
              color: [
                {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#ddcc66',
                    },
                    {
                      offset: 1,
                      color: '#ddc889',
                    },
                  ],
                  globalCoord: false,
                },
              ],
              data: data.data, // data个数代表波浪数
              backgroundStyle: {
                // borderWidth: 1,
                color: '#fff',
              },
              label: {
                normal: {
                  textStyle: {
                    fontSize: 18,
                    color: '#fff',
                  },
                },
              },
              outline: {
                // show: false
                borderDistance: 0,
                itemStyle: {
                  borderWidth: 2,
                  borderColor: '#b2acac',
                },
              },
            },
          ]
        }
        myChart.option && myChart.setOption(myChart.option)
      }
    }

    return {
      ...toRefs(data),
      orderLiquid
    }
  }
}
</script>

<style lang="less" scoped>
#chartDom {
  width: 15vw;
  height: 15vh;
}
</style>
